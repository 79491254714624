@tailwind base;
@tailwind components;
@tailwind utilities;

.table-container {
  overflow-y: auto;
  height: 300px; /* adjust the height to your needs */
}

.table-container thead {
  top: 0;
  background-color: #fff; /* or any other background color you want */
  z-index: 1;
}

.table-container tbody {
  overflow-y: auto;
  height: 100%; /* make the tbody take up the remaining space */
}
